import { t } from "i18next";
console.log(t);
export const PAGE_URL = {
  LOGIN: "/login",
  SIGNUP: "/signup",
  FORGOT_PASSWORD: "/forgot-password",
  NEW_PASSWORD: "/new-password",
  KNOWLEDGE_BASE: "/knowledge-base",
  // CHAT: "/chat",
  CONFIGURE_CHAT: "/settings/configure-assistant",
  SETTINGS: "/settings",
  INTEGRATION: "/settings/integration",
  WORKSPACE: "/settings/workspace",
  SEARCH_DOC: "/search-document",
  USERS: "/settings/users",
  CONFIGURE_ACTIONS: "/settings/configureactions",
  PROJECT: "/project",
  BILLINGS_AND_PLANS: "/settings/billings",
  FORMBUILDER: "/settings/forms",
  CREATE_FORMS: "/settings/forms/select",
  CREATE_FORM: "/settings/forms/create",
  EDIT_FORMS: "/settings/forms/:formId/edit",

  FORMS: "/forms",

  FORMS_CREATE: "/forms/:id/create",
  FORMS_SELECT: "/forms/select",

  SUBSCRIPTION_BILLINGS: "/settings/upgradesubscription",
  WORKFLOW: "/workflows",
  RUN_WORKFLOW: "/workflows/runworkflow",
  EDIT_WORKFLOW: "/workflows/editworkflow",
  EXTERNAL_ASSISTANT: "/settings/assistants",
  CREATE_EXTERNAL_ASSISTANT: "/settings/assistants/create",
  UPDATE_ASSISTANT: "/settings/assistants/update",
  ASSITANT_CHAT: "/assistant-chat-history/chat",
  ASSITANT_CHAT_HISTORY: "/assistant-chat-history",

  CHANGE_PASSWORD: "/change-password",
  PRIVACY_POLICY: "/privacy-policy",
  // PRICING: "/pricing",
  SUBSCRIPTION: "/subscription",
  REPORT: "/reports",
  REPORT_SELECTION_FORM300: "/reports/form300/select",
  FORM300: "/reports/form300",
  FORM300_CREATE: "/reports/form300/create",
  FORM300_EDIT: "/reports/form300/:form300Id/edit",
  FORM300_VIEW: "/reports/form300/:form300Id/view",
  REPORT_SELECTION_FORM301: "/reports/form301/select",
  FORM301: "/reports/form301",
  FORM301_CREATE: "/reports/form301/create",
  FORM301_EDIT: "/reports/form301/:form301Id/edit",
  FORM301_VIEW: "/reports/form301/:form301Id/view",
  REPORT_DASHBOARD: "/dashboard",
  CustomFormBuilder: "/forms",
};

export const PAGE_TITLE = [
  { path: "/knowledge-base", title: "Knowledge Base" },
  { path: "/reports", title: "Incident Reports" },
  // { path: "/reports", title: t("reports") },
  { path: "/reports/form300/create", title: "Create Form 300" },
  { path: "/reports/form300/:form300Id/edit", title: "Edit Form 300" },
  { path: "/reports/form300", title: "Form 300 History" },
  { path: "/reports/form300/select", title: "Select Reports for Form300" },
  { path: "/reports/form301/create", title: "Create Form 301" },
  { path: "/reports/form301/:form301Id/edit", title: "Edit Form 301" },
  { path: "/reports/form301", title: "Form 301 History" },
  { path: "/reports/form301/select", title: "Select Reports for Form301" },
  { path: "/workflows", title: "Workflows" },
  {
    path: "/workflows/runworkflow",
    title: "Run Workflow",
    breadcrumb: [
      { path: "/workflows", title: "Workflows" },
      { path: "", title: "Run Workflow" },
    ],
  },
  {
    path: "/workflows/editworkflow",
    title: "Edit Workflow",
    breadcrumb: [
      { path: "/workflows", title: "Workflows" },
      { path: "", title: "Edit Workflow" },
    ],
  },
  // { path: "/chat", title: "Chat" },
  {
    path: "/settings/configure-assistant",
    title: "External Assistant",
    breadcrumb: [
      { path: "/settings", title: "Settings" },
      { path: "", title: "External Assistant" },
    ],
  },
  {
    path: "/settings/forms",
    title: "Forms",
    breadcrumb: [
      { path: "/settings", title: "Settings" },
      { path: "", title: "Forms" },
    ],
  },
  {
    path: "/settings/forms/select",
    breadcrumb: [
      { path: "/settings", title: "Settings" },
      { path: "/settings/forms", title: "Forms" },
      { path: "", title: "Create" },
    ],
  },
  {
    path: "/settings/forms/create",
    breadcrumb: [
      { path: "/settings", title: "Settings" },
      { path: "/settings/forms", title: "Forms" },
      { path: "", title: "Create" },
    ],
  },
  {
    path: "/settings/forms/:formId/edit",
    breadcrumb: [
      { path: "/settings", title: "Settings" },
      { path: "/settings/forms", title: "Forms" },
      { path: "", title: "Update" },
    ],
  },
  { path: "/settings", title: "Settings" },
  { path: "/search-document", title: "Search Documents" },
  {
    path: "/settings/integration",
    title: "Integration",
    breadcrumb: [
      { path: "/settings", title: "Settings" },
      { path: "", title: "Integration" },
    ],
  },
  {
    path: "/settings/workspace",
    breadcrumb: [
      { path: "/settings", title: "Settings" },
      { path: "", title: "Workspace" },
    ],
  },
  {
    path: "/settings/assistants",
    breadcrumb: [
      { path: "/settings", title: "Settings" },
      { path: "", title: "Assistants" },
    ],
  },
  {
    path: "/settings/users",
    breadcrumb: [
      { path: "/settings", title: "Settings" },
      { path: "", title: "Users" },
    ],
  },
  {
    path: "/settings/assistants/create",
    breadcrumb: [
      { path: "/settings", title: "Settings" },
      { path: "/settings/assistants", title: "Assistants" },
      { path: "", title: "Create" },
    ],
  },
  {
    path: "/settings/assistants/update",
    breadcrumb: [
      { path: "/settings", title: "Settings" },
      { path: "/settings/assistants", title: "Assistants" },
      { path: "", title: "Update" },
    ],
  },
  {
    path: "/settings/configureactions",
    breadcrumb: [
      { path: "/settings", title: "Settings" },
      { path: "", title: "Configure Actions" },
    ],
  },
  {
    path: "/project",
    // breadcrumb: [
    //   { path: "/project", title: "Projects" },
    //   // { path: "", title: "Project" },
    // ],
    title: "Projects",
  },
  {
    path: "/settings/billings",
    breadcrumb: [
      { path: "/settings", title: "Settings" },
      { path: "", title: "Billings & Plans" },
    ],
  },
  {
    path: "/settings/upgradesubscription",
    breadcrumb: [
      { path: "/settings", title: "Settings" },
      { path: "", title: "Subscription & Billings" },
    ],
  },
  {
    path: "/assistants",
    title: "Assistants",
  },
  {
    path: "/assistants/create",
    breadcrumb: [
      { path: "/assistants", title: "Assistants" },
      { path: "", title: "Create Assistant" },
    ],
  },
  {
    path: "/assistants/update",
    breadcrumb: [
      { path: "/assistants", title: "Assistants" },
      { path: "", title: "Update Assistant" },
    ],
  },
  { path: "/assistant-chat", title: t("assistantChat") },
  { path: "/assistant-chat-history", title: "Recent Chats" },
  { path: "/dashboard", title: "Dashboard" },
  { path: "/forms", title: "Forms" },
  {
    path: "/forms/select",
    breadcrumb: [
      { path: "/forms", title: "Forms" },
      { path: "", title: "Select" },
    ],
  },
  { path: "/forms/:id/create", title: "Fill The Form" },
];
