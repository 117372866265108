import {
  Box,
  Button,
  IconButton,
  InputBase,
  Paper,
  Tooltip,
  Stack,
  Drawer,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { RiAddCircleLine } from "react-icons/ri";
import SearchIcon from "@mui/icons-material/Search";
import DataGridTable from "../dataGrid";
import CreateWorkflow from "./NewWorkflow/createWorkflow";
import { useDispatch } from "react-redux";
import {
  getAllworkflowThunk,
  LatestRunWorkflowThunk,
} from "../../../redux/slices/workflow.slice";
import { setSnackbar } from "../../../redux/slices/common.slice";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { PAGE_URL } from "../../../constants/pageUrl.constant";
import PlayIcon from "../../../assets/icons/play.svg";
import Pencil from "../../../assets/icons/pencil.svg";
import { MdHistory } from "react-icons/md";
import RunWorkflowHistory from "./runWorkflowHistory";
import SwapVertIcon from "@mui/icons-material/SwapVert";
const Workflow = () => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [drawerState, setDrawerState] = useState("create");
  const [isLoading, setIsLoading] = useState(false);
  const [statusFilter] = useState("all");
  const [sortKey, setSortKey] = useState();

  const [workflow, setWorkflow] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const [historyDrawer, setHistoryDrawer] = useState(false);

  const handleSorting = (e, key) => {
    if (key) {
      if (!sortKey) {
        setSortKey(key);
      } else if (
        sortKey &&
        sortKey.startsWith("-") &&
        sortKey.substring(1) === key
      ) {
        // If the key starts with '-', remove it
        setSortKey(sortKey.substring(1));
      } else {
        // If the key doesn't start with '-', add '-'
        setSortKey(`-${key}`);
      }
    }
  };
  const fetchAllworkflow = async () => {
    setIsLoading(true);
    let response = await dispatch(
      getAllworkflowThunk({
        status: statusFilter,
        sort: sortKey,
      })
    );
    if (response?.payload?.status) {
      setWorkflow(response?.payload?.data ?? []);
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: response?.payload?.message || "Internal server error",
        })
      );
    }
    setIsLoading(false);
  };

  const Navigate = useNavigate();
  useEffect(
    () => {
      fetchAllworkflow();
    }, // eslint-disable-next-line
    [statusFilter, sortKey]
  );

  const fetchRunWorkflowDetails = async (id) => {
    let response = await dispatch(LatestRunWorkflowThunk(id));
    const { payload } = response;
    if (payload && payload?.status === true) {
      Navigate(PAGE_URL.RUN_WORKFLOW, {
        state: { workflow_id: payload?.data?.run_id },
      });
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  function ActionCell(params) {
    return (
      <>
        {" "}
        <Stack direction="row" spacing={0} alignItems="center">
          <Tooltip title="Edit">
            <IconButton
              sx={{ color: "#000000", padding: "0px" }}
              onClick={() => {
                setIsOpenDrawer(true);
                setDrawerState("edit");
                setSelectedRowData(params.row);
                Navigate(PAGE_URL.EDIT_WORKFLOW, {
                  state: { workflow_id: params.row.id, isEdit: true },
                });
              }}
            >
              <img
                alt="Edit Details"
                src={Pencil}
                width={24}
                height={24}
                className="actionIcon"
                cursor={"pointer"}
              />
            </IconButton>
          </Tooltip>
          {params.row.is_action_associated && (
            <Tooltip title="Run">
              <IconButton
                sx={{ color: "#000000", padding: "0px" }}
                onClick={() => {
                  setSelectedRowData(params.row);
                  fetchRunWorkflowDetails(params.row.id);
                }}
              >
                <img alt="Run Workflow" src={PlayIcon} width={24} height={24} />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="History">
            <IconButton
              sx={{
                color: "#000000",
                padding: params.row.is_action_associated ? "5px" : "0px",
              }}
              onClick={() => {
                setSelectedRowData(params.row);
                setHistoryDrawer(true);
              }}
            >
              <MdHistory width={24} height={24} color="#CA9688" />
            </IconButton>
          </Tooltip>
        </Stack>
      </>
    );
  }

  function DateCell(params) {
    return moment(params.value).format("MM-DD-yyyy");
  }

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "name")}
        >
          Name
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 250,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "description")}
        >
          Description
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "created_at",
      headerName: "Created At",
      flex: 1,
      renderCell: DateCell,
      minWidth: 120,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "created_at")}
        >
          Created At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      flex: 1,
      renderCell: DateCell,
      minWidth: 120,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "updated_at")}
        >
          Updated At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 1,
      renderCell: ActionCell,
      minWidth: 120,
    },
  ];

  return (
    <>
      <Box className="content-header kb-list-header mb-4">
        <span></span>
        <Box variant="div" component="div" className="content-header-right">
          <Paper
            elevation={0}
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: "auto",
              borderRadius: "50px",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
          <Button
            variant="contained"
            size="large"
            className="primary-button add-btn"
            endIcon={<RiAddCircleLine />}
            disableRipple
            onClick={() => {
              setIsOpenDrawer(true);
              setDrawerState("create");
            }}
          >
            Create Workflow
          </Button>
        </Box>
      </Box>
      {/* <DataTable /> */}
      <Box sx={{ flexGrow: 1 }}>
        <DataGridTable
          data={
            searchTerm
              ? workflow.filter((item) =>
                  item.name.toLowerCase().includes(searchTerm.toLowerCase())
                )
              : workflow
          }
          columns={columns}
          isLoading={isLoading}
          totalRow={0}
        />
      </Box>
      {isOpenDrawer && (
        <CreateWorkflow
          isOpenDrawer={isOpenDrawer}
          setIsOpenDrawer={setIsOpenDrawer}
          drawerState={drawerState}
          setDrawerState={setDrawerState}
          selectedRowData={selectedRowData}
          setSelectedRowData={setSelectedRowData}
          fetchAllworkflow={fetchAllworkflow}
        />
      )}

      {historyDrawer && (
        <Drawer
          anchor={"right"}
          className="side-drawer"
          open={historyDrawer}
          onClose={() => {
            setHistoryDrawer(false);
          }}
        >
          <RunWorkflowHistory
            workflow_id={selectedRowData.id}
            setHistoryDrawer={setHistoryDrawer}
            workflow={"workflow"}
          />
        </Drawer>
      )}
    </>
  );
};

export default Workflow;
