import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ProjectLocationAutoCompleteSuggesstionThunk } from "../../../../redux/slices/tenant.slice";
import CloseIcon from "@mui/icons-material/Cancel";
import FileUpload from "./fileUpload";
import { useDispatch } from "react-redux";
import { getFormById } from "../../../../redux/slices/forms.slice";
export default function FormRenderer({
  formId = null,
  sections = null,
  setFormData,
}) {
  const [locationOption, setLocationOption] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selected, setSelected] = useState(null); // To track the selected button
  const [formSections, setFormSections] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchFormById = async () => {
    setLoading(true);
    try {
      const response = await dispatch(getFormById({ formId }));
      const { payload } = response;
      if (payload?.status) setFormSections(payload?.data?.form_struct_json);
    } catch (e) {}
    setLoading(false);
  };
  useEffect(() => {
    if (formId) fetchFormById();
    else {
      console.log("hello", sections);
      setFormSections(sections);
    }
  }, []);

  const handleSelect = (value) => {
    setSelected(value);
  };
  let timeOut = null;
  const handleAutocompleteChange = (event, values) => {
    if (values !== "") {
      if (timeOut) clearTimeout(timeOut);

      timeOut = setTimeout(async () => {
        try {
          const response = await ProjectLocationAutoCompleteSuggesstionThunk({
            location: values,
          });
          const suggestionList = response.data; // Adjust this based on the structure of your response
          let temp = [];
          suggestionList?.predictions?.map((element) => {
            temp.push({
              label: element?.description,
              value: element?.place_id,
            });
          });
          setLocationOption(temp);
        } catch (error) {}
      }, 500);
    }
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const removeFile = (fileToRemove) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file.name !== fileToRemove.name)
    );
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let data = new FormData(e.target);
    data = Object.fromEntries(data);
    console.log(data);
    setFormData(data);
  };
  return (
    <>
      {loading ? (
        <Box
          sx={{
            margin: "auto",
            height: "500px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            padding: "24px",
            backgroundColor: "#ffffff",
            border: "1px solid #036185",
            borderRadius: "8px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          <form onSubmit={handleSubmit}>
            {formSections &&
              formSections?.map((section, secIndex) => (
                <Accordion
                  key={section.id}
                  sx={{
                    "& .MuiAccordionSummary-root": {
                      "& .MuiTypography-root": {
                        fontSize: "18px",
                      },

                      height: "56px !important",
                    },
                  }}
                  defaultExpanded={true}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
                    sx={{
                      backgroundColor: "#036185",
                      color: "#ffffff",
                      "& .MuiAccordionSummary-content": {
                        margin: 0,
                      },
                    }}
                  >
                    <Typography variant="h6">{section.name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: "16px" }}>
                    <Grid container spacing={3}>
                      {section.questions.map((question) => (
                        <Grid item xs={question.width}>
                          <Typography variant="label" sx={{ fontWeight: 600 }}>
                            {question.label}
                            {question.required && (
                              <span style={{ color: "red" }}> *</span>
                            )}
                          </Typography>
                          {question.type === "Text Field" && (
                            <TextField
                              fullWidth
                              variant="outlined"
                              placeholder="Your answer here"
                              required={question.required}
                            />
                          )}
                          {question.type === "CheckBox" && (
                            <FormControlLabel
                              control={<Checkbox />}
                              // label={question.label}
                              // labelPlacement="end"
                              sx={{ fontWeight: 600, marginLeft: "10px" }}
                              className="center"
                            />
                          )}
                          {question.type === "Number" && (
                            <TextField
                              type="number"
                              fullWidth
                              variant="outlined"
                              placeholder="Enter a number"
                              required={question.required}
                            />
                          )}

                          {question.type === "Date & Time" && (
                            <TextField
                              type="datetime-local"
                              fullWidth
                              variant="outlined"
                              required={question.required}
                            />
                          )}
                          {question.type === "Time" && (
                            <TextField
                              type="time"
                              fullWidth
                              variant="outlined"
                              required={question.required}
                            />
                          )}
                          {question.type === "Date" && (
                            <TextField
                              type="date"
                              fullWidth
                              variant="outlined"
                              required={question.required}
                            />
                          )}
                          {question.type === "Location" && (
                            <Autocomplete
                              className="project-location-autocomplete"
                              freeSolo
                              onInputChange={(event, values) => {
                                handleAutocompleteChange(event, values);
                              }}
                              options={locationOption}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label=""
                                  id="outlined-basic"
                                  fullWidth
                                  variant="outlined"
                                  name="location"
                                  placeholder="Please, Enter location  "
                                />
                              )}
                            />
                          )}

                          {question.type === "Media" && (
                            <FileUpload
                              name={question.id}
                              required={question.required}
                              onFilesChange={(files) => {}}
                            />
                          )}

                          {question.type === "Select" && (
                            <ButtonGroup
                              sx={{ display: "flex", height: "55px" }}
                            >
                              <Button
                                sx={{
                                  flex: 1,
                                  backgroundColor:
                                    selected === "Yes"
                                      ? "#64bcd2"
                                      : "transparent",
                                  ":hover": {
                                    backgroundColor:
                                      selected === "Yes"
                                        ? "#64bcd2"
                                        : "transparent",
                                  },
                                }}
                                variant={
                                  selected === "Yes" ? "contained" : "outlined"
                                }
                                onClick={() => handleSelect("Yes")}
                              >
                                Yes
                              </Button>
                              <Button
                                sx={{
                                  flex: 1,
                                  backgroundColor:
                                    selected === "No"
                                      ? "#64bcd2"
                                      : "transparent",
                                  ":hover": {
                                    backgroundColor:
                                      selected === "No"
                                        ? "#64bcd2"
                                        : "transparent",
                                  },
                                }}
                                variant={
                                  selected === "No" ? "contained" : "outlined"
                                }
                                onClick={() => handleSelect("No")}
                              >
                                No
                              </Button>
                              <Button
                                sx={{
                                  flex: 1,
                                  backgroundColor:
                                    selected === "N/A"
                                      ? "#64bcd2"
                                      : "transparent",
                                  ":hover": {
                                    backgroundColor:
                                      selected === "N/A"
                                        ? "#64bcd2"
                                        : "transparent",
                                  },
                                }}
                                variant={
                                  selected === "N/A" ? "contained" : "outlined"
                                }
                                onClick={() => handleSelect("N/A")}
                              >
                                N/A
                              </Button>
                            </ButtonGroup>
                          )}

                          {question.type === "Radio Buttons" && (
                            <FormControl
                              component="fieldset"
                              fullWidth
                              required={question.required}
                            >
                              <RadioGroup
                                name={`radio-group-${question.id}`} // Use question.id for unique naming
                              >
                                {question.options.map((option, index) => (
                                  <FormControlLabel
                                    key={index}
                                    value={option}
                                    control={<Radio />}
                                    label={option}
                                  />
                                ))}
                              </RadioGroup>
                            </FormControl>
                          )}
                        </Grid>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
          </form>
        </Box>
      )}
    </>
  );
}
