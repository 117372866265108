import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";

const TableStyle = {
  "& .MuiDataGrid-columnHeaders": {
    "& .MuiDataGrid-columnHeadersInner": {
      "& .MuiDataGrid-columnHeader": {
        backgroundColor: "#64bcd2",
        color: "#FFFFFF",
        fontSize: "16px",
        "& .MuiDataGrid-columnHeaderTitleContainer": {
          "& .MuiDataGrid-iconButtonContainer": {
            "& svg": {
              fill: "#FFFFFF",
            },
          },
        },
        "& .MuiDataGrid-columnSeparator": {
          display: "none",
        },
      },
    },
  },
  "& .MuiDataGrid-cell": {
    py: 2,
    "& .MuiDataGrid-withBorderColor": {
      display: "none !important",
    },
    "&:focus": {
      outline: "none !important",
    },
  },
  "& .MuiDataGrid-virtualScroller": {
    overflowY: "auto",
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#cccccc",
    },
    "& .MuiDataGrid-row": {
      "&.Mui-hovered": {
        backgroundColor: "rgb(255 115 100 / 5%)",
      },
    },
  },
  "& .MuiDataGrid-root": {
    maxHeight: "440px", // Ensure root DataGrid respects height when data is present
  },
};

// Custom No Rows Overlay Component
function CustomNoRowsOverlay() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%", // Ensure it fills available space
      }}
    >
      <Typography variant="subtitle1" color="textSecondary">
        No data available
      </Typography>
    </Box>
  );
}

const DataGridTable = ({
  data,
  columns,
  totalRow,
  paginationModel,
  setPaginationModel,
  isLoading,
}) => {
  // Dynamically adjust grid height based on data availability
  const gridHeight = data.length === 0 ? "200px" : "auto"; // Change height when no data
  const columnsWithNoSorting = columns.map((column) => ({
    ...column,
    sortable: false,
  }));
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#FFFFFF",
        height: gridHeight, // Adjust the height based on the data
      }}
    >
      <DataGrid
        sx={TableStyle}
        rows={data}
        rowCount={totalRow}
        getRowId={(row) => row.kbId || row.id}
        columns={columnsWithNoSorting}
        hideFooter={paginationModel === undefined ? true : false}
        pageSizeOptions={[5, 10, 20, 50]}
        loading={isLoading}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        disableColumnMenu
        disableColumnSelector
        disableRowSelectionOnClick
        getRowHeight={() => "auto"}
        slots={{
          NoRowsOverlay: CustomNoRowsOverlay, // Set custom "No Data" overlay
        }}
        // slotProps={{
        //   grid: {
        //     style: { minHeight: gridHeight }, // Dynamically change grid minHeight based on data
        //   },
        // }}
        // initialState={{
        //   sorting: {
        //     sortModel: [
        //       { field: "created_at", sort: "desc" }, // Default descending sort for 'id'
        //     ],
        //   },
        // }}
      />
    </div>
  );
};

export default DataGridTable;
