import React, { useEffect, useState } from "react";
import { HiDocumentText } from "react-icons/hi2";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Divider,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../../assets/logo/ver-logo.png";
import { CiSearch } from "react-icons/ci";
import { IoMdSettings } from "react-icons/io";
import BookIcon from "../../utils/icon/BookIcon";
import { HiDocumentReport } from "react-icons/hi";
import Workflow from "../../utils/icon/Workflow";
import ExternalAssistant from "../../utils/icon/ExternalAssitant";
import { BsChatLeftText } from "react-icons/bs";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { MdDashboard } from "react-icons/md";
import { SiGoogleforms } from "react-icons/si";

const SideMenu = ({ openSideMenu, setOpenSideMenu }) => {
  const Navigate = useNavigate();
  const location = useLocation();
  const MenuOptions = [
    {
      label: "Projects",
      path: "/project",
      icon: <HiDocumentText size={32} />,
    },
    {
      label: "Dashboard",
      path: "/dashboard",
      icon: <MdDashboard size={32} />,
    },
    {
      label: "Incident Reports",
      path: "/reports",
      icon: <HiDocumentReport size={32} />,
    },
    // {
    //   label: "Forms",
    //   path: "/forms",
    //   icon: <SiGoogleforms size={32} />,
    // },
    {
      label: "Knowldge Base (KB)",
      path: "/knowledge-base",
      icon: <BookIcon size={32} />,
    },
    {
      label: "Search",
      path: "/search-document",
      icon: <CiSearch size={32} />,
    },
    // {
    //   label: "KB Chat",
    //   path: "/chat",
    //   icon: <DocumentChat size={36} width={36} height={36} />,
    // },
    {
      label: "Workflows",
      path: "/workflows",
      icon: <Workflow size={32} />,
    },
    // {
    //   label: "Assistants",
    //   path: "/assistants",
    //   icon: <RiAccountCircleLine size={36} />,
    // },
    {
      label: "Assistants Chat",
      path: "/assistant-chat-history",
      icon: <BsChatLeftText size={30} />,
    },
    // {
    //   label: "External Assistant",
    //   path: "/configure-assistant",
    //   icon: <ExternalAssistant size={32} />,
    // },
    {
      label: "Settings",
      path: "/settings",
      icon: <IoMdSettings size={32} />,
    },
  ];

  const handleNavigate = (path) => {
    Navigate(path);
    setOpenSideMenu((prev) => !prev);
  };

  const isMobile = useMediaQuery("(max-width: 601px)");

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    if (window.innerWidth < 601) {
      setOpenSideMenu(false);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [isMobile]);

  const handleDrawerClose = () => {
    setOpenSideMenu((prev) => !prev);
  };
  const drawerWidth = 240;

  const handleDrawerToggle = () => {
    setOpenSideMenu((prevState) => !prevState);
  };
  return (
    <>
      {!isMobile ? (
        <Drawer
          open={openSideMenu}
          variant="persistent"
          anchor="left"
          className={`sidebar-wrapper ${
            openSideMenu ? "sidebar-menu-open" : "ismobile-closed"
          } `}
        >
          {windowWidth < 601 && (
            <>
              <IconButton
                sx={{ justifyContent: "end" }}
                onClick={handleDrawerClose}
              >
                <ChevronLeftIcon />
              </IconButton>
              <Divider />
            </>
          )}
          <Box className="list-wrap">
            <List className="sidebar">
              <Box></Box>
              <Box className="px-2 mb-2 text-center" key="side-logo">
                <img
                  src={logo}
                  alt="logo-main"
                  loading="lazy"
                  className="large-logo"
                  style={{
                    maxWidth: "100px",
                    margin: "0 auto",
                    cursor: "pointer",
                  }}
                  onClick={() => Navigate("/project")}
                />
              </Box>
              {MenuOptions.map((item, index) => {
                return (
                  <ListItem
                    disablePadding
                    key={index}
                    onClick={() => handleNavigate(item.path)}
                    className={
                      location.pathname.includes(item.path) ? "active" : ""
                      // (location.pathname === "/assistant-chat" && index === 4)
                      //   ? "active"
                      //   : ""
                    }
                  >
                    <ListItemButton>
                      <ListItemIcon className="icon-wrap" title={item.label}>
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.label}
                        className="menu-text-wrap"
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Drawer>
      ) : (
        <Drawer
          variant="temporary"
          open={openSideMenu}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          className={`sidebar-wrapper ${
            openSideMenu
              ? "sidebar-menu-open"
              : "sidebar-menu-closed ismobile-closed"
          } ${windowWidth > 601 ? "sidebar-menu-open" : "ismobile-closed"}`}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {windowWidth < 601 && (
            <>
              <IconButton
                sx={{ justifyContent: "end" }}
                onClick={handleDrawerClose}
              >
                <ChevronLeftIcon />
              </IconButton>
              <Divider />
            </>
          )}
          <Box className="list-wrap">
            <List className="sidebar">
              <Box></Box>
              <Box className="px-2 mb-2 text-center" key="side-logo">
                <img
                  src={logo}
                  alt="logo-main"
                  loading="lazy"
                  className="large-logo"
                  style={{
                    maxWidth: "100px",
                    margin: "0 auto",
                    cursor: "pointer",
                  }}
                  onClick={() => Navigate("/knowledge-base")}
                />
              </Box>
              {MenuOptions.map((item, index) => {
                return (
                  <ListItem
                    disablePadding
                    key={index}
                    onClick={() => handleNavigate(item.path)}
                    className={
                      location.pathname.includes(item.path) ? "active" : ""
                    }
                  >
                    <ListItemButton>
                      <ListItemIcon className="icon-wrap" title={item.label}>
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.label}
                        className="menu-text-wrap"
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Drawer>
      )}
    </>
  );
};

export default SideMenu;
