import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Modal,
  DialogContent,
  CardActions,
  IconButton,
} from "@mui/material";

import "../../../../styles/externalAssistant.scss";

import { useNavigate } from "react-router-dom";

import { PAGE_URL } from "../../../../constants/pageUrl.constant";

import AddIcon from "../../../../assets/icons/add.svg";
import CloseIcon from "@mui/icons-material/Close";
import { RiCloseLine } from "react-icons/ri";
import { IoDocumentOutline } from "react-icons/io5";
import { IoMdDocument } from "react-icons/io";
import CustomizedDialog from "../../Dialog";
import FormRenderer from "./formRenderer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch } from "react-redux";
import { getAllForm } from "../../../../redux/slices/forms.slice";
const steps = ["Basic", "Instructions"];

const CreateForm = () => {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedForm, setSelectedForm] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [formTemplates, setFormTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchAllTemplateForms = async () => {
    setLoading(true);
    try {
      const response = await dispatch(
        getAllForm({
          custom_filter: JSON.stringify({ is_template: true }),
          list: "all",
        })
      );
      const { payload } = response;
      if (payload?.status) {
        setFormTemplates(payload?.data);
      }
    } catch (e) {}
    setLoading(false);
  };
  useEffect(() => {
    fetchAllTemplateForms();
  }, []);
  // const formTemplates = [
  //   {
  //     id: "f-1",
  //     title: "Employee Induction Form",
  //     description:
  //       "A comprehensive form to manage team safety culture and inspection data.",
  //     sections: [
  //       {
  //         id: "s-1",
  //         name: "Personal Information",
  //         questions: [
  //           {
  //             id: "q-s-1-1",
  //             label: "Full Name",
  //             type: "Text Field",
  //             required: true,
  //             width: 12,
  //           },
  //           {
  //             id: "q-s-1-2",
  //             label: "Email Address",
  //             type: "Text Field",
  //             required: true,
  //             width: 12,
  //           },
  //           {
  //             id: "q-s-1-3",
  //             label: "Phone Number",
  //             type: "Text Field",
  //             required: true,
  //             width: 6,
  //           },
  //           {
  //             id: "q-s-1-4",
  //             label: "Date of Birth",
  //             type: "Date",
  //             required: false,
  //             width: 6,
  //           },
  //         ],
  //       },
  //       {
  //         id: "s-2",
  //         name: "Team Information",
  //         questions: [
  //           {
  //             id: "q-s-2-1",
  //             label: "Team Name",
  //             type: "Text Field",
  //             required: true,
  //             width: 12,
  //           },
  //           {
  //             id: "q-s-2-2",
  //             label: "Team Size",
  //             type: "Number",
  //             required: true,
  //             width: 6,
  //           },
  //           {
  //             id: "q-s-2-3",
  //             label: "Is Active Team?",
  //             type: "CheckBox",
  //             required: false,
  //             width: 6,
  //           },
  //         ],
  //       },
  //     ],
  //   },
  // ];
  const BootstrapInput = {
    marginBottom: "20px",
    "& label": {
      width: "100%",
      "&.Mui-focused": {
        color: "#273167",
      },
    },
    "& .MuiOutlinedInput-root": {
      "& .Mui-disabled": {
        backgroundColor: "rgba(39, 49, 103, 0.1)",
      },
      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#273167 !important",
          borderWidth: "1px !important",
        },
      },
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#273167 !important",
          borderWidth: "1px !important",
        },
      },
    },
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCancel = () => {
    navigate(PAGE_URL.FORMS);
  };

  const handleNext = () => {
    if (!title) {
      setTitleError("Title is Required!");
      return;
    }
    if (!description) {
      setDescriptionError("Description is Required!");
      return;
    }
    setActiveStep((prev) => prev + 1);
  };
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <div className="mt mx-auto main-div-assistant">
          <Stepper
            sx={{
              paddingBottom: "20px",
              display: "flex",
              justifyContent: "center",
            }}
            flexDirection={{ sm: "column !important" }}
            activeStep={activeStep}
          >
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step
                  className={`step-lable ${
                    activeStep === index && activeStep !== 2
                      ? "step-lable-before"
                      : ""
                  }`}
                  key={label}
                  {...stepProps}
                  sx={{
                    backgroundColor:
                      activeStep === index
                        ? "#CA9688"
                        : activeStep > index
                        ? "#fff"
                        : "#fff",
                  }}
                >
                  <StepLabel
                    className={`${
                      activeStep === index
                        ? "step-circle active-step"
                        : "inactive-text"
                    } ${activeStep > index ? "step-circle" : ""} `}
                    {...labelProps}
                    sx={{ color: "white !important" }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Grid
            item
            xs={12}
            sm={12}
            md={11}
            lg={11}
            xl={11}
            sx={{ paddingX: "40px !important" }}
          >
            <hr style={{ marginTop: "0px", marginBottom: "40px" }}></hr>
          </Grid>
          <>
            {activeStep + 1 === 1 ? (
              <Grid container spacing={4} sx={{ justifyContent: "center" }}>
                <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
                  <p
                    style={{
                      paddingBottom: "15px",
                      color: "#0D333F",
                      fontWeight: 500,
                      fontSize: "15px",
                      fontFamily: "Poppins",
                    }}
                  >
                    Give a public name and description to the Form.
                  </p>
                  <Grid item className="margin-bottom-input">
                    <Box variant="div" component="div" sx={BootstrapInput}>
                      <Typography
                        color="#0D333F"
                        variant="body1"
                        component="label"
                      >
                        Name
                      </Typography>
                      <TextField
                        fullWidth
                        id="name"
                        name="name"
                        label=""
                        variant="outlined"
                        placeholder="Enter Name"
                        size="small"
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value);
                          setTitleError("");
                        }}
                      />
                      {titleError && (
                        <div className="form-error-message">{titleError}</div>
                      )}
                    </Box>
                  </Grid>
                  <Grid item className="margin-bottom-input">
                    <Box variant="div" component="div" sx={BootstrapInput}>
                      <Typography
                        variant="body1"
                        component="label"
                        color="#0D333F"
                      >
                        Description
                      </Typography>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Enter Description"
                        size="small"
                        name="description"
                        rows={4}
                        multiline
                        value={description}
                        onChange={(e) => {
                          setDescription(e.target.value);
                          setDescriptionError("");
                        }}
                      />
                      {descriptionError && (
                        <div className="form-error-message">
                          {descriptionError}
                        </div>
                      )}
                      <Typography
                        variant="subtitle1"
                        sx={{ fontSize: "14px", color: "#989898" }}
                      >
                        The description can help users know what the form is
                        for.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            ) : activeStep + 1 === 2 ? (
              <>
                <Grid container spacing={4} sx={{ justifyContent: "center" }}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyItems="center"
                      px={{ md: 6, sm: 0 }}
                      pt={2}
                    >
                      <Grid item xs={12} md={12} lg={12}>
                        <p
                          style={{
                            paddingBottom: "15px",
                            color: "#0D333F",
                            fontWeight: 500,
                            fontSize: "16px",
                          }}
                        >
                          This is where you create your forms by selecting below
                          options.
                        </p>
                      </Grid>
                    </Grid>
                    <Grid item className="margin-bottom-input">
                      <Box variant="div" component="div" sx={BootstrapInput}>
                        <Grid
                          container
                          spacing={4}
                          alignItems="center"
                          justifyItems="center"
                          pt={4}
                          px={{ sm: 0, md: 6 }}
                        >
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Card
                              sx={{
                                height: "100%",
                                position: "relative",
                                cursor: "pointer",
                              }}
                              className={`assistantCard-hover "custom-active"
                                    
                                }`}
                              onClick={() =>
                                navigate(PAGE_URL.CREATE_FORM, {
                                  state: {
                                    title,
                                    description,
                                  },
                                })
                              }
                            >
                              <CardContent>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    paddingBottom: "10px",
                                  }}
                                >
                                  <img
                                    alt="Add"
                                    src={AddIcon}
                                    width={24}
                                    height={24}
                                  />
                                  <Typography
                                    sx={{
                                      marginLeft: "10px",
                                      fontSize: "18px",
                                      color: "#036185",
                                    }}
                                    variant="h6"
                                  >
                                    Custom Set Up
                                  </Typography>
                                </Box>
                                <hr style={{ color: "#EBEBEB", opacity: 1 }} />
                                <Typography
                                  sx={{ fontSize: "14px", color: "grey" }}
                                  variant="h6"
                                >
                                  Create your Form with guided customization for
                                  your unique goals.
                                </Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Card
                              sx={{
                                minWidth: 200,
                                height: "100%",
                                position: "relative",
                                cursor: "pointer",
                              }}
                              className={`assistantCard-hover "custom-active"
                                   
                                }`}
                              onClick={() => {
                                setIsModalOpen(true);
                              }}
                            >
                              <CardContent>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    paddingBottom: "10px",
                                  }}
                                >
                                  <IoDocumentOutline
                                    style={{
                                      color: "#036185",
                                      height: "24px",
                                      width: "24px",
                                    }}
                                  />

                                  <Typography
                                    sx={{
                                      marginLeft: "10px",
                                      fontSize: "18px",
                                      color: "#036185",
                                    }}
                                    variant="h6"
                                  >
                                    Use Form Library
                                  </Typography>
                                </Box>
                                <hr style={{ color: "#EBEBEB", opacity: 1 }} />
                                <Typography
                                  sx={{ fontSize: "14px", color: "grey" }}
                                  variant="h6"
                                >
                                  Begin with a pre-made starting point, then
                                  customize from there.
                                </Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </Box>

                      <Grid />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              ""
            )}
            <Box
              flexDirection={{ sm: "column", md: "row" }}
              sx={{
                display: "flex",
                pt: 2,
                justifyContent: "center",
              }}
              className="Create-assitant-media"
            >
              {activeStep !== 0 && (
                <Button
                  onClick={handleCancel}
                  sx={{
                    padding: "8px 32px !important",
                    borderRadius: "5px",
                    minWidth: "auto",
                    backgroundColor: "#fff",
                    color: "#036185 !important",
                    border: 1,
                    borderColor: "#273167",
                    "&:hover": {
                      backgroundColor: "#fff",
                      color: "#273167",
                      border: 1,
                      borderColor: "#273167",
                    },
                  }}
                >
                  Cancel
                </Button>
              )}
              <Button
                onClick={activeStep === 0 ? handleCancel : handleBack}
                sx={{
                  padding: "8px 32px !important",
                  borderRadius: "5px",
                  marginLeft: "15px",
                  minWidth: "auto",
                  backgroundColor: "#fff",
                  color: "#036185",
                  border: 1,
                  borderColor: "#273167",
                  "&:hover": {
                    color: "#fff",
                    backgroundColor: "#036185",
                    border: 1,
                    borderColor: "#036185",
                  },
                }}
              >
                {activeStep === 0 ? "Cancel" : "Back"}
              </Button>
              {activeStep === 0 && (
                <Button
                  disabled={isLoading}
                  sx={{
                    padding: "8px 32px !important",
                    borderRadius: "5px",
                    marginLeft: "15px",
                    backgroundColor: "#036185 ",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#fff !important",
                      color: "#273167 !important",
                      border: 1,
                      borderColor: "#036185",
                    },
                  }}
                  className={`${
                    activeStep + 1 === 2 || isLoading ? "disablestep" : ""
                  }`}
                  onClick={handleNext}
                >
                  Next
                </Button>
              )}
            </Box>
          </>
        </div>
      </Box>

      <Modal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
      >
        <Box
          className="default-assitant-media"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            padding: "20px 30px 30px 30px",
            width: "1000px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "20px",
            }}
          >
            <Typography
              component="div"
              color="#0D333F"
              sx={{ fontSize: "18px" }}
            >
              Choose Template of Form
            </Typography>
            {/* <Box
              className="close-btn"
              onClick={() => setIsModalOpen(false)}
              sx={{ cursor: "pointer" }}
            >
              <RiCloseLine fill="#000000" size="small" />
            </Box> */}
            <IconButton
              aria-label="close"
              onClick={() => setIsModalOpen(false)}
              sx={{
                position: "absolute",
                right: 20,
                top: 12,
                color: "black",
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Grid container spacing={4}>
            {!formTemplates.length && (
              <Box className="center" sx={{ margin: "auto", height: "200px" }}>
                No Form Templates Found
              </Box>
            )}
            {formTemplates?.map((form, i) => {
              return (
                <Grid key={form.id} item xs={12} sm={6} md={6} lg={6}>
                  <Card
                    sx={{
                      maxHeight: 215,
                      height: "100%",
                      position: "relative",
                      cursor: "pointer",
                      boxShadow: " 0px 2px 27px 0px #0000001F",
                    }}
                    className={"custom-active"}
                    onClick={() => {
                      navigate(`/settings/forms/${form.id}/edit`, {
                        state: {
                          title,
                          description,
                        },
                      });
                      setIsModalOpen(false);
                    }}
                  >
                    <CardContent
                      sx={{ padding: "16px !important", height: "125px" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                          paddingBottom: "10px",
                        }}
                      >
                        <IoMdDocument />
                        <Typography
                          sx={{
                            fontSize: "18px",
                            marginLeft: "10px",
                          }}
                          variant="h6"
                        >
                          {`${
                            form.title.length > 35
                              ? form.title.substring(0, 35) + "..."
                              : form.title
                          }`}
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "grey",
                        }}
                        variant="h6"
                      >
                        {`${
                          form.description.length > 130
                            ? form.description.substring(0, 130) + "..."
                            : form.description
                        }`}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Box
                        sx={{
                          marginLeft: "auto",
                        }}
                      >
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedForm(form);
                            setOpenPreview(true);
                          }}
                          size="small"
                          sx={{
                            color: "white",
                            padding: "8px 10px",
                            mt: 1,
                            // fontSize: "14px !important",
                          }}
                          className="primary-button"
                          endIcon={<VisibilityIcon />}
                        >
                          Preview
                        </Button>
                      </Box>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Modal>
      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Preview Form"}
        open={openPreview}
        handleClose={() => setOpenPreview(false)}
      >
        <DialogContent>
          <FormRenderer formId={selectedForm?.id} />
        </DialogContent>
      </CustomizedDialog>
    </>
  );
};

export default CreateForm;
