import {
  Box,
  Button,
  Card,
  CardContent,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getFormsByType,
  getFormTypes,
} from "../../../redux/slices/forms.slice";
import { IoMdCreate, IoMdDocument, IoMdEye } from "react-icons/io";
import { DiDatabase } from "react-icons/di";
import CustomizedDialog from "../Dialog";
import FormRenderer from "../settings/formbuilder/formRenderer";
import HistoryIcon from "@mui/icons-material/History";
import { useNavigate } from "react-router-dom";
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function Forms() {
  const [selectedFormType, setSelectedFormType] = useState("custom");
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [typeloading, setTypeLoading] = useState(false);
  const [formTypes, setFormTypes] = useState([]);
  const [forms, setForms] = useState([]);
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedFormId, setSelectedFormId] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setSelectedFormType(formTypes[newValue].type);
    setValue(newValue);
  };

  const fetchAllFormTypes = async () => {
    setLoading(true);
    try {
      const response = await dispatch(getFormTypes());
      const { payload } = response;
      if (payload?.status) {
        setFormTypes(payload?.data);
      }
    } catch (e) {}
    setLoading(false);
  };

  const fetchFormsByType = async () => {
    setTypeLoading(true);
    try {
      const response = await dispatch(
        getFormsByType({ formType: selectedFormType })
      );
      const { payload } = response;
      if (payload?.status) {
        setForms(payload?.data);
      }
    } catch (e) {}
    setTypeLoading(false);
  };
  useEffect(() => {
    fetchAllFormTypes();
  }, []);

  useEffect(() => {
    fetchFormsByType();
  }, [selectedFormType]);
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        variant="div"
        component="div"
        className="space-between media-assitant"
      >
        <Typography sx={{ color: "#00446B !important" }}>
          Select and Fill the Form
        </Typography>
      </Box>
      <hr />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }} mb={2}>
        <Tabs
          value={value}
          className="configure-action-tab"
          onChange={handleChange}
          aria-label="Free form assistants"
        >
          {formTypes?.map((item, index) => (
            <Tab label={item?.name} {...a11yProps(index)} />
          ))}
        </Tabs>
      </Box>

      <Grid container spacing={4}>
        {forms.length > 0 ? (
          forms.map((form) => (
            <Grid item xs={12} sm={6} md={4} key={form.id}>
              <Card
                sx={{
                  maxHeight: 250,
                  height: "100%",
                  position: "relative",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  cursor: "pointer",
                  "&:hover": {
                    transform: "translateY(-2px)", // Moves the card up by 10px
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", // Adds shadow for emphasis
                  },
                }}
                onClick={() => navigate(`/forms/${form.id}/create`)}
              >
                <CardContent>
                  <Box
                    className="space-between"
                    sx={{
                      paddingBottom: "10px",
                    }}
                  >
                    <IconButton className="New-Chat">
                      <IoMdDocument color="#CA9688" />
                    </IconButton>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Tooltip title="Preview Form">
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenPreview(true);
                            setSelectedFormId(form);
                          }}
                          className="New-Chat"
                        >
                          <IoMdEye color="#CA9688" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                  <Typography
                    sx={{
                      color: "#036185",
                      fontSize: "16px",
                      marginTop: "10px",
                      fontWeight: "700",
                    }}
                    variant="h6"
                  >
                    {form.title}
                  </Typography>
                  <hr style={{ color: "#EBEBEB", opacity: 1 }} />
                  <Typography
                    sx={{ fontSize: "14px", color: "#4F4F4F" }}
                    variant="h6"
                  >
                    {`${
                      form.description.length > 115
                        ? form.description.substring(0, 115) + "..."
                        : form.description
                    }`}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Box
            className="no-data-found"
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              mt: 1,
            }}
          >
            <Stack spacing={0} justifyContent="center" alignItems="center">
              <DiDatabase size={50} />
              <span>No data found</span>
            </Stack>
          </Box>
        )}
      </Grid>
      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Preview Form"}
        open={openPreview}
        handleClose={() => setOpenPreview(false)}
      >
        <DialogContent>
          <FormRenderer formId={selectedFormId?.id} />
        </DialogContent>
      </CustomizedDialog>
    </Box>
  );
}
