import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Grid,
  Button,
  Box,
  IconButton,
  Tooltip,
  Select,
  InputBase,
  Paper,
  Stack,
} from "@mui/material";

import { IoDocumentText } from "react-icons/io5";
import { RiAddCircleLine, RiFilter2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch } from "react-redux";
import { getAllForm300 } from "../../../redux/slices/form300.slice";
import DataGridTable from "../dataGrid";
import moment from "moment";
import DateRangePicker from "../dateRangePicker";
import FilterDrawer from "../../layout/filterDrawer";
import SearchIcon from "@mui/icons-material/Search";
import { MdRemoveRedEye } from "react-icons/md";
import Pencil from "../../../assets/icons/pencil.svg";
import EyeIcon from "../../../assets/icons/eye.svg";
import DeleteIcon from "../../../assets/icons/deleteRed.svg";

import SwapVertIcon from "@mui/icons-material/SwapVert";
import dayjs from "dayjs";
import { fetchAllFormsInstances } from "../../../redux/slices/forms.slice";

export default function FormLogs() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formHistoryData, setFormHistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRow, setTotalRow] = useState(0);

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const [dateRange_create, setDateRange_create] = useState(["", ""]);
  const [dateRange_update, setDateRange_update] = useState(["", ""]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortKey, setSortKey] = useState();
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  useEffect(() => {
    const getAllForms = async () => {
      setIsLoading(true);
      const forms = [
        {
          id: 1,
          created_by: "Alice Johnson",
          form_type: "Custom",
          created_at: "2023-10-01T10:30:00Z",
          updated_at: "2023-10-02T14:45:00Z",
        },
        {
          id: 2,
          created_by: "Bob Smith",
          form_type: "employee Induction",
          created_at: "2023-09-15T09:15:00Z",
          updated_at: "2023-09-20T16:00:00Z",
        },
        {
          id: 3,
          created_by: "Clara White",
          form_type: "Work Permit",
          created_at: "2023-08-25T13:00:00Z",
          updated_at: "2023-09-01T12:30:00Z",
        },
        {
          id: 4,
          created_by: "David Brown",
          form_type: "Custom",
          created_at: "2023-10-02T08:00:00Z",
          updated_at: "2023-10-02T10:00:00Z",
        },
      ];

      setFormHistoryData(forms);

      setTotalRow(4);
      let custom_filter = {};

      if (dateRange_create?.length > 0) {
        custom_filter.created_at__gte =
          dateRange_create[0] == ""
            ? undefined
            : dayjs(dateRange_create[0]).format("YYYY-MM-DD");
        custom_filter.created_at__lte =
          dateRange_create[1] == ""
            ? undefined
            : dayjs(dateRange_create[1]).add(1, "day").format("YYYY-MM-DD");
      }
      if (dateRange_update?.length > 0) {
        custom_filter.updated_at__gte =
          dateRange_update[0] == ""
            ? undefined
            : dayjs(dateRange_update[0]).format("YYYY-MM-DD");
        custom_filter.updated_at__lte =
          dateRange_update[1] == ""
            ? undefined
            : dayjs(dateRange_update[1]).add(1, "day").format("YYYY-MM-DD");
      }

      //   let response = await dispatch(
      //     fetchAllFormsInstances({
      //       page_number: paginationModel.page + 1,
      //       page_size: paginationModel.pageSize,
      //       sort: sortKey,
      //       custom_filter: JSON.stringify(custom_filter),
      //     })
      //   );
      //   const { payload } = response;
      //   if (payload?.status) {
      //     const forms = [
      //       {
      //         created_by: "Alice Johnson",
      //         form_type: "custom",
      //         created_at: "2023-10-01T10:30:00Z",
      //         updated_at: "2023-10-02T14:45:00Z",
      //       },
      //       {
      //         created_by: "Bob Smith",
      //         form_type: "employee_induction",
      //         created_at: "2023-09-15T09:15:00Z",
      //         updated_at: "2023-09-20T16:00:00Z",
      //       },
      //       {
      //         created_by: "Clara White",
      //         form_type: "work_permit",
      //         created_at: "2023-08-25T13:00:00Z",
      //         updated_at: "2023-09-01T12:30:00Z",
      //       },
      //       {
      //         created_by: "David Brown",
      //         form_type: "custom",
      //         created_at: "2023-10-02T08:00:00Z",
      //         updated_at: "2023-10-02T10:00:00Z",
      //       },
      //     ];

      //     setFormHistoryData(forms);

      //     setTotalRow(4);
      //   }
      setIsLoading(false);
    };
    getAllForms();
  }, []);

  function DateCell(params) {
    return moment(params.value).format("MM-DD-yyyy");
  }

  function ActionCell(params) {
    return (
      <>
        <Stack direction="row" spacing={0} alignItems="center">
          <Tooltip title="View">
            <IconButton
              sx={{ color: "#000000" }}
              //   onClick={() => {
              //     setIsOpenDrawer(true);
              //     setDrawerState("view");
              //     setSelectedRowData(params.row);
              //   }}
            >
              <img
                alt="View Details"
                src={EyeIcon}
                width={24}
                height={24}
                className="actionIcon"
                cursor={"pointer"}
              />
            </IconButton>
          </Tooltip>

          <Tooltip title="Edit">
            <IconButton sx={{ color: "#000000", padding: "0px" }}>
              <img
                alt="Edit Details"
                src={Pencil}
                width={24}
                height={24}
                className="actionIcon"
                cursor={"pointer"}
                // onClick={() => {
                //   setDrawerState("edit");
                //   setIsOpenDrawer(true);
                //   setViewOpen(params.row);
                //   setSelectedRowData(params.row);
                // }}
              />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete">
            <IconButton
              sx={{ color: "#000000", padding: "0" }}
              //   onClick={() => {
              //     setIsDelete(true);
              //     setSelectedRowData(params.row);
              //     // getKbValues();
              //   }}
            >
              <img
                alt="Delete Details"
                src={DeleteIcon}
                width={24}
                height={24}
                className="actionIcon"
                cursor={"pointer"}
              />
            </IconButton>
          </Tooltip>
        </Stack>
      </>
    );
  }

  const handleSorting = (e, key) => {
    if (key) {
      if (!sortKey) {
        setSortKey(key);
      } else if (
        sortKey &&
        sortKey.startsWith("-") &&
        sortKey.substring(1) === key
      ) {
        // If the key starts with '-', remove it
        setSortKey(sortKey.substring(1));
      } else {
        // If the key doesn't start with '-', add '-'
        setSortKey(`-${key}`);
      }
    }
  };
  const columns = [
    {
      field: "form_type",
      headerName: "Form Type",
      flex: 2, // Flex value of 2 (20% of the space if the total flex is 10)
      minWidth: 130,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "form_name")}
        >
          Form Type
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "created_by",
      headerName: "Created By",
      flex: 3, // Flex value of 4 (40% of the space if the total flex is 10)
      minWidth: 200, // Adjust minWidth as needed
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "description")}
        >
          Created By
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "created_at",
      headerName: "Created At",

      // Flex value of 1 (10% of the space if the total flex is 10)
      width: 130, // Adjust minWidth as needed
      renderCell: DateCell,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "created_at")}
        >
          Created At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "updated_at",
      headerName: "Updated At",

      // Flex value of 1 (10% of the space if the total flex is 10)
      width: 140, // Adjust minWidth as needed
      renderCell: DateCell,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "updated_at")}
        >
          Updated At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "moreaction",
      headerName: "Action",
      flex: 1,
      renderCell: ActionCell,
      minWidth: 120,
    },
  ];
  return (
    <>
      <div className="history">
        <Box className="content-header kb-list-header mb-4">
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              navigate("/forms");
            }}
            startIcon={<ArrowBackIcon />}
            className="primary-button back-button"
          >
            Back
          </Button>
          <Box variant="div" component="div" className="content-header-right">
            <Paper
              elevation={0}
              // component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "auto",
                borderRadius: "50px",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
            <Button
              variant="contained"
              size="large"
              className="primary-button add-btn"
              endIcon={<RiAddCircleLine />}
              onClick={() => navigate("/forms/select")}
            >
              Fill Your Form
            </Button>
            <Button
              variant="contained"
              size="large"
              className="primary-button"
              endIcon={<RiFilter2Line sx={{ "font-size": "22px" }} />}
              disableRipple
              onClick={() => setOpenFilterDrawer(true)}
            >
              Filters
            </Button>
          </Box>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <DataGridTable
            data={
              searchTerm
                ? formHistoryData.filter((item) =>
                    item.form_name
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  )
                : formHistoryData
            }
            columns={columns}
            setPaginationModel={setPaginationModel}
            isLoading={isLoading}
            paginationModel={paginationModel}
            totalRow={totalRow}
          />
        </Box>
      </div>
      <FilterDrawer
        open={openFilterDrawer}
        setOpenFilterDrawer={setOpenFilterDrawer}
      >
        <Box className="joiningdate">
          <Typography variant="body1" component="label">
            Created At
          </Typography>
          <Grid item xs={3}>
            <Box>
              <DateRangePicker
                dateRange={dateRange_create}
                onDateRangeChange={(e) => setDateRange_create(e)}
                machineFilter={null} // Adjust according to your use case
                disableClearable={false} // or true depending on your requirement
              />
            </Box>
          </Grid>
        </Box>
        <Box className="joiningdate">
          <Typography variant="body1" component="label">
            Updated At
          </Typography>
          <Grid item xs={3}>
            <Box>
              <DateRangePicker
                dateRange={dateRange_update}
                onDateRangeChange={(e) => setDateRange_update(e)}
                machineFilter={null} // Adjust according to your use case
                disableClearable={false} // or true depending on your requirement
              />
            </Box>
          </Grid>
        </Box>
      </FilterDrawer>
    </>
  );
}
