import React, { useEffect, useMemo, useState } from "react";
import {
  AppBar,
  // Avatar,
  Box,
  Button,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Breadcrumbs,
  IconButton,
  useMediaQuery,
  Badge,
} from "@mui/material";
import { RiArrowDownSLine } from "react-icons/ri";
import { Link, matchPath, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { PAGE_TITLE, PAGE_URL } from "../../constants/pageUrl.constant";
import { useDispatch } from "react-redux";
import { forgotPasswordThunk, logout } from "../../redux/slices/user.slice";
import "../../styles/landingpage.scss";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { GoSignOut } from "react-icons/go";
import { RiLockPasswordFill } from "react-icons/ri";
import MenuIcon from "@mui/icons-material/Menu";
import { setSnackbar } from "../../redux/slices/common.slice";
import { IoNotificationsSharp } from "react-icons/io5";
import NotificationDrawer from "../layout/NotificationDrawer";
import { getAllNotification } from "../../redux/slices/notifications.slice";

const Header = ({ setOpenSideMenu, openSideMenu, isAdmin }) => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElNotification, setAnchorElNotification] = useState(false);
  const [notifications, setnotifications] = useState(null);
  const [openNotificationDrawer, setOpenNotificationDrawer] = useState(false);
  const [totalNotifications, settotalNotifications] = useState(0);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const location = useLocation();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleNotificationClick = (event) => {
    setAnchorElNotification((prev) => !prev);
    setOpenNotificationDrawer(true);
  };

  const handleLogout = async () => {
    await dispatch(logout());
    Navigate(PAGE_URL.LOGIN);
  };

  const handleBilling = async () => {
    Navigate(PAGE_URL.BILLINGS_AND_PLANS);
    setAnchorEl(null);
  };

  const handleChangePassword = async () => {
    handleClose();
    try {
      let response = await dispatch(
        forgotPasswordThunk({
          email: JSON.parse(localStorage.getItem("user"))?.user?.email,
        })
      );
      const { payload } = response;
      if (payload && payload?.status) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "4 digit verification code sent to email Successfully",
          })
        );
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: error || "Internal server error",
        })
      );
    }
  };
  const tempnotifications = [
    {
      id: 1,
      image: "https://via.placeholder.com/50",
      title: "New Message",
      description: "You have received a new message from John.",
      time: "9:00 PM",
      isReviewed: true,
      isRead: true,
    },
    {
      id: 2,
      image: "https://via.placeholder.com/50",
      title: "System Alert",
      description: "System maintenance is scheduled for midnight.",
      time: "8:30 PM",
      isReviewed: false,
      isRead: false,
    },
    {
      id: 3,
      image: "https://via.placeholder.com/50",
      title: "New Message",
      description: "You have received a new message from John.",
      time: "9:00 PM",
      isReviewed: true,
      isRead: true,
    },
    {
      id: 4,
      image: "https://via.placeholder.com/50",
      title: "System Alert",
      description: "System maintenance is scheduled for midnight.",
      time: "8:30 PM",
      isReviewed: false,
      isRead: false,
    },
    {
      id: 5,
      image: "https://via.placeholder.com/50",
      title: "New Message",
      description: "You have received a new message from John.",
      time: "9:00 PM",
      isReviewed: true,
      isRead: true,
    },
    {
      id: 6,
      image: "https://via.placeholder.com/50",
      title: "System Alert",
      description: "System maintenance is scheduled for midnight.",
      time: "8:30 PM",
      isReviewed: false,
      isRead: false,
    },
    {
      id: 7,
      image: "https://via.placeholder.com/50",
      title: "New Message",
      description: "You have received a new message from John.",
      time: "9:00 PM",
      isReviewed: true,
      isRead: true,
    },
    {
      id: 8,
      image: "https://via.placeholder.com/50",
      title: "System Alert",
      description: "System maintenance is scheduled for midnight.",
      time: "8:30 PM",
      isReviewed: false,
      isRead: false,
    },
    {
      id: 9,
      image: "https://via.placeholder.com/50",
      title: "New Message",
      description: "You have received a new message from John.",
      time: "9:00 PM",
      isReviewed: true,
      isRead: true,
    },
    {
      id: 10,
      image: "https://via.placeholder.com/50",
      title: "System Alert",
      description: "System maintenance is scheduled for midnight.",
      time: "8:30 PM",
      isReviewed: false,
      isRead: false,
    },
  ];
  const fetchNotifications = async () => {
    try {
      let response = await dispatch(
        getAllNotification({
          page: 0,
          pageSize: 100000,
        })
      );
      const { payload } = response;
      if (payload && payload?.status) {
        setnotifications(payload?.data);
        settotalNotifications(
          payload?.data.filter((item) => !item.is_read).length
        );
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: error || "Internal server error",
        })
      );
    }

    // setnotifications(tempnotifications);
    // settotalNotifications(2);
  };

  useEffect(() => {
    fetchNotifications();
  }, [anchorElNotification]);

  const pageTitle = useMemo(() => {
    return PAGE_TITLE.find((item) => matchPath(item.path, location.pathname));
  }, [location]);

  const userName = JSON.parse(localStorage.getItem("user"))?.user?.first_name;

  const handleDrawerOpen = () => {
    setOpenSideMenu(!openSideMenu);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isMobile = useMediaQuery("(max-width: 601px)");

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    if (window.innerWidth < 601) {
      setOpenSideMenu(false);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [isMobile]);

  return (
    <>
      <AppBar
        position="fixed"
        open={openSideMenu}
        className={`dashboard-navbar ${
          openSideMenu ? "sidebar-open" : "sidebar-closed"
        } `}
      >
        <Toolbar sx={{ py: 3 }}>
          {windowWidth < 601 && (
            <IconButton
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ color: "#000", mx: 2, ...(open && { display: "none" }) }}
              className="menu-bar"
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box variant="div" component="div" className="navbar-right">
            {pageTitle?.breadcrumb ? (
              <Breadcrumbs aria-label="breadcrumb" separator="|">
                {pageTitle?.breadcrumb?.map((ele, index) =>
                  ele.path ? (
                    <Link
                      key={index}
                      underline="hover"
                      to={ele.path}
                      className="breadcrumbsLink"
                    >
                      {ele.title}
                    </Link>
                  ) : (
                    <Typography
                      key={index}
                      variant="h3"
                      noWrap
                      className={`page-title_heading curruntPageLink`}
                    >
                      {ele?.title}
                    </Typography>
                  )
                )}
              </Breadcrumbs>
            ) : (
              <Typography
                variant="h3"
                color="#FFFFFF"
                noWrap
                className="page-title_heading curruntPageLink"
              >
                {pageTitle?.title}
              </Typography>
            )}
            <Box variant="div" component="div" className="user-info">
              {/* <Avatar src={User} /> */}
              <IconButton aria-label="cart" onClick={handleNotificationClick}>
                <Badge
                  badgeContent={totalNotifications}
                  max={999}
                  sx={{
                    "& .MuiBadge-badge": {
                      backgroundColor: "red", // Set the background color of the badge
                      color: "white", // Optional: Set the text color inside the badge
                    },
                  }}
                >
                  <IoNotificationsSharp
                    fill="var(--color-theme-secondary)"
                    style={{ fontSize: "1.5rem" }}
                  />
                </Badge>
              </IconButton>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                disableRipple
              >
                <span className="mr-1" mr={2}>
                  {userName}
                </span>{" "}
                <RiArrowDownSLine />
              </Button>
              <Menu
                id="basic-menu"
                className="user-info-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={() => handleBilling()} key={"3"}>
                  <FaMoneyBillTrendUp />{" "}
                  <Typography margin={"0 10px"} variant="p">
                    Billing Overview
                  </Typography>
                </MenuItem>
                <hr class="MuiDivider-root MuiDivider-fullWidth css-39bbo6" />
                <MenuItem
                  className="user-info-menu-item"
                  key={"1"}
                  onClick={handleChangePassword}
                >
                  <RiLockPasswordFill />{" "}
                  <Typography margin={"0 10px"} variant="p">
                    Change Password
                  </Typography>
                </MenuItem>
                <hr class="MuiDivider-root MuiDivider-fullWidth css-39bbo6" />
                <MenuItem onClick={() => handleLogout()} key={"2"}>
                  <GoSignOut />{" "}
                  <Typography margin={"0 10px"} variant="p">
                    Sign Out
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <NotificationDrawer
        openDrawer={openNotificationDrawer}
        setOpenDrawer={setOpenNotificationDrawer}
        notifications={notifications}
        // allNotifications={
        //     notifications?.userNotifications?.allNotifications
        // }
        // unreadNotifications={
        //     notifications?.userNotifications?.unreadNotifications
        // }
        fetchNotifications={fetchNotifications}
        totalNotifications={totalNotifications}
        setnotifications={setnotifications}
        settotalNotifications={settotalNotifications}
      />
    </>
  );
};

export default Header;
