import { Box, Button, IconButton, Stack, Tooltip } from "@mui/material";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { RiAddCircleLine } from "react-icons/ri";
import DataGridTable from "../../dataGrid";
import { getAllProject } from "../../../../redux/slices/tenant.slice";
import { setSnackbar } from "../../../../redux/slices/common.slice";
import EyeIcon from "../../../../assets/icons/eye.svg";
import CreateProject from "./CreateProject";
import Pencil from "../../../../assets/icons/pencil.svg";
import DeleteIcon from "../../../../assets/icons/deleteRed.svg";
import DeleteConfigureAction from "../ConfigureAction/DeleteConfigureAction";
import SwapVertIcon from "@mui/icons-material/SwapVert";
const Project = () => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [drawerState, setDrawerState] = useState("create");
  const [viewOpen, setViewOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [totalRow, setTotalRow] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [sortKey, setSortKey] = useState();
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const [project, setProject] = useState([]);

  const dispatch = useDispatch();
  const handleSorting = (e, key) => {
    console.log("hello");
    if (key) {
      if (!sortKey) {
        setSortKey(key);
      } else if (
        sortKey &&
        sortKey.startsWith("-") &&
        sortKey.substring(1) === key
      ) {
        // If the key starts with '-', remove it
        setSortKey(sortKey.substring(1));
      } else {
        // If the key doesn't start with '-', add '-'
        setSortKey(`-${key}`);
      }
    }
  };
  const fetchAllProject = async () => {
    setIsLoading(true);
    let response = await dispatch(
      getAllProject({
        page_number: paginationModel.page + 1,
        page_size: paginationModel.pageSize,
        sort: sortKey,
      })
    );
    const { payload } = response;
    if (payload?.status) {
      setProject(payload?.data);
      setTotalRow(payload?.total_object);
    } else
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAllProject();
  }, [paginationModel, sortKey]);

  function ActionCell(params) {
    return (
      <>
        <Stack direction="row" spacing={0} alignItems="center">
          <Tooltip title="View">
            <IconButton
              sx={{ color: "#000000" }}
              onClick={() => {
                setIsOpenDrawer(true);
                setDrawerState("view");
                setSelectedRowData(params.row);
              }}
            >
              <img
                alt="View Details"
                src={EyeIcon}
                width={24}
                height={24}
                className="actionIcon"
                cursor={"pointer"}
              />
            </IconButton>
          </Tooltip>

          <Tooltip title="Edit">
            <IconButton sx={{ color: "#000000", padding: "0px" }}>
              <img
                alt="Edit Details"
                src={Pencil}
                width={24}
                height={24}
                className="actionIcon"
                cursor={"pointer"}
                onClick={() => {
                  setDrawerState("edit");
                  setIsOpenDrawer(true);
                  setViewOpen(params.row);
                  setSelectedRowData(params.row);
                }}
              />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete">
            <IconButton
              sx={{ color: "#000000", padding: "0" }}
              onClick={() => {
                setIsDelete(true);
                setSelectedRowData(params.row);
                // getKbValues();
              }}
            >
              <img
                alt="Delete Details"
                src={DeleteIcon}
                width={24}
                height={24}
                className="actionIcon"
                cursor={"pointer"}
              />
            </IconButton>
          </Tooltip>
        </Stack>
      </>
    );
  }

  function DataCell(params) {
    return moment(params?.value).format("MM-DD-yyyy");
  }

  const columns = [
    {
      field: "projectid",
      headerName: "Project Id",
      minWidth: 200,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "id")}
        >
          Project Id
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },

    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "name")}
        >
          Name
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },

    {
      field: "description",
      headerName: "Description",
      minWidth: 300,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "description")}
        >
          Description
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "radius",
      headerName: "Radius",
      minWidth: 100,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "radius")}
        >
          Radius
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "created_at",
      headerName: "Created At",
      flex: 1,
      renderCell: DataCell,
      minWidth: 120,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "created_at")}
        >
          Created At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      flex: 1,
      renderCell: DataCell,
      minWidth: 120,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "updated_at")}
        >
          Updated At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "moreaction",
      headerName: "Action",
      flex: 1,
      renderCell: ActionCell,
      minWidth: 120,
    },
  ];
  return (
    <>
      <Box className="content-header justify-content-end kb-list-header mb-4">
        <Box variant="div" component="div" className="content-header-right">
          {/* <Paper
            elevation={0}
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: "auto",
              borderRadius: "50px",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper> */}
          <Button
            variant="contained"
            size="large"
            className="primary-button add-btn"
            endIcon={<RiAddCircleLine />}
            disableRipple
            onClick={() => {
              setDrawerState("create");
              setIsOpenDrawer(true);
              setSelectedRowData({});
            }}
          >
            CREATE PROJECT
          </Button>
        </Box>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <DataGridTable
          data={project}
          columns={columns}
          setPaginationModel={setPaginationModel}
          isLoading={isLoading}
          paginationModel={paginationModel}
          totalRow={totalRow}
        />
      </Box>

      {isOpenDrawer && (
        <CreateProject
          drawerState={drawerState}
          setDrawerState={setDrawerState}
          setIsOpenDrawer={setIsOpenDrawer}
          isOpenDrawer={isOpenDrawer}
          fetchAllProject={fetchAllProject}
          viewOpen={viewOpen}
          selectedRow={selectedRowData}
        />
      )}

      {isDelete && (
        <DeleteConfigureAction
          isDelete={isDelete}
          setIsDelete={setIsDelete}
          selectedRowData={selectedRowData}
          isLoading={isLoading}
          fetchAllKnowledgeBase={fetchAllProject}
          kbmodule={"project"}
        />
      )}
    </>
  );
};
export default Project;
