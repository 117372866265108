import { API_URL } from "../../constants/apiUrl.constant";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const createForm = createAsyncThunk(
  "createForm",
  async ({
    data,
    title,
    description,
    is_template,
    reference_template,
    type,
  }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.FORM_BUILDER}`,
        {
          form_struct_json: data,
          title: title,
          description: description,
          is_template,
          reference_template,
          type,
        },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const updateForm = createAsyncThunk(
  "updateForm",
  async ({ data, title, description, formId, reference_template, type }) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}${API_URL.FORM_BUILDER}/${formId}`,
        {
          form_struct_json: data,
          title: title,
          description: description,
          reference_template,
          type,
        },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllForm = createAsyncThunk("AllForm", async (payload) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}${API_URL.FORM_BUILDER}`,
      {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage?.getItem("user")).access_token
          }`,
        },
        params: payload,
      }
    );
    return response?.data;
  } catch (error) {
    return error.response.data;
  }
});
export const fetchAllFormsInstances = createAsyncThunk(
  "AllFormInstances",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.FORMS}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
          params: payload,
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const getFormTypes = createAsyncThunk("FormTypes", async (payload) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}${API_URL.FORM_BUILDER}/types`,
      {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage?.getItem("user")).access_token
          }`,
        },
      }
    );
    return response?.data;
  } catch (error) {
    return error.response.data;
  }
});

export const getFormById = createAsyncThunk(
  "getFormById",
  async ({ formId }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.FORM_BUILDER}/${formId}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const deleteFormById = createAsyncThunk(
  "deleteFormById",
  async ({ formId }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}${API_URL.FORM_BUILDER}/${formId}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const getFormsByType = createAsyncThunk(
  "getFormsByType",
  async ({ formType }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.FORM_TYPES}/${formType}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

// Assistants slice
const FormSlice = createSlice({
  name: "Form",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    error: null,
  },
  extraReducers: (builder) => {},
});

export default FormSlice.reducer;
