import React from "react";
import Login from "../components/common/login";
import ForgotPassword from "../components/common/Forgotpassword";
import NewPassword from "../components/common/NewPassword";
// import Chat from "../components/common/chat";
import ConfigureChat from "../components/common/configureChat";
import Settings from "../components/common/settings/settings";
import IntegrationList from "../components/common/settings/integration/IntegrationList";
import KnowledgeBase from "../components/common/knowledgeBase";
import Search from "../components/common/search";
import { PAGE_URL } from "../constants/pageUrl.constant";
import Workspace from "../components/common/settings/workspace/workspace";
import Users from "../components/common/settings/users/users";
// import ConfigureActions from "../components/common/settings";
import Workflow from "../components/common/Workflow/workflow";
import RunWorkflow from "../components/common/Workflow/NewWorkflow/runWorkflow";
import PrivacyPolicy from "../components/common/privacyPolicy";
import Assistants from "../components/common/Assistants/Assistants";
import CreateAssistant from "../components/common/Assistants/CreateAssistant";
import AssistantChat from "../components/common/Assistants/AssitantChat";
import AssitantChatHistory from "../components/common/Assistants/AssitantChatHistory";
import ChangePassword from "../components/common/ChangePassword";
import NewLandingPage from "../components/desktop/newLandingPage";
import Pricing from "../components/desktop/PricingPage";
import SignUp from "../components/common/SignUp";
import EditWorkflow from "../components/common/Workflow/editWorkflow";
import Subscription from "../components/common/Subcription";
import BilingsAndPlans from "../components/common/settings/BillingsAndPlans/BilingsAndPlans";
import SubscriptionBilling from "../components/common/settings/BillingsAndPlans/SubscriptionBilling";
import ConfigureAction from "../components/common/settings/ConfigureAction/ConfigureAction";
import Project from "../components/common/settings/Project/Project";
import Reports from "../components/common/reports";
import ReportDashbaord from "../components/common/reportDashboard";
import ReportSelectionForm300 from "../components/common/ReportForms/reportSelectionForm300";
import Form300 from "../components/common/ReportForms/Form300";
import Form300Logs from "../components/common/ReportForms/form300History";
import ReportSelectionForm301 from "../components/common/ReportForms/reportSelectionForm301";
import Form301 from "../components/common/ReportForms/Form301";
import Form301Single from "../components/common/ReportForms/Form301Single";
import Form301Logs from "../components/common/ReportForms/Form301History";
import CustomFormBuilder from "../components/common/settings/formbuilder/customform";
import Form from "../components/common/settings/formbuilder/forms";
import FormRenderer from "../components/common/settings/formbuilder/formRenderer";
import CreateForm from "../components/common/settings/formbuilder/createForm";
import Forms from "../components/common/forms/forms";
import FormList from "../components/common/forms/formlist";
import SingleForm from "../components/common/forms/singleForm";
export const publicRoutes = [
  {
    path: "/",
    component: <NewLandingPage />,
    title: "Landingpage",
  },
  // {
  //   path: PAGE_URL.PRICING,
  //   component: <Pricing />,
  //   title: "PricingPage",
  // },
  {
    path: PAGE_URL.PRIVACY_POLICY,
    component: <PrivacyPolicy />,
    title: "PrivacyPolicy",
  },
];

export const authRoutes = [
  {
    path: PAGE_URL.LOGIN,
    component: <Login />,
    title: "Loginpage",
  },
  {
    path: PAGE_URL.SIGNUP,
    component: <SignUp />,
    title: "SignUp",
  },
  {
    path: PAGE_URL.SUBSCRIPTION,
    component: <Subscription />,
    title: "subscription",
  },
  {
    path: `${PAGE_URL.CHANGE_PASSWORD}`,
    component: <ChangePassword />,
    title: "Change password",
  },
  {
    path: `${PAGE_URL.FORGOT_PASSWORD}`,
    component: <ForgotPassword />,
    title: "Forgot password",
  },
  {
    path: `${PAGE_URL.NEW_PASSWORD}`,
    component: <NewPassword />,
    title: "New password",
  },
];

export const privateRoutes = [
  {
    path: PAGE_URL.KNOWLEDGE_BASE,
    component: <KnowledgeBase />,
    title: "Knowledge Base",
  },
  {
    path: PAGE_URL.REPORT,
    component: <Reports />,
    title: "Reports",
  },
  {
    path: PAGE_URL.REPORT_SELECTION_FORM300,
    component: <ReportSelectionForm300 />,
    title: "Reports Selection",
  },
  {
    path: PAGE_URL.FORM300_CREATE,
    component: <Form300 mode="create" />,
    title: "Form300 create",
  },
  {
    path: PAGE_URL.FORM300_EDIT,
    component: <Form300 mode="edit" />,
    title: "Form300 edit",
  },
  {
    path: PAGE_URL.FORM300_VIEW,
    component: <Form300 mode="view" />,
    title: "Form300 view",
  },
  {
    path: PAGE_URL.FORM300,
    component: <Form300Logs />,
    title: "Form300",
  },
  {
    path: PAGE_URL.REPORT_SELECTION_FORM301,
    component: <ReportSelectionForm301 />,
    title: "Reports Selection form301",
  },
  {
    path: PAGE_URL.FORM301_CREATE,
    component: <Form301 mode="create" />,
    title: "Form301 create",
  },
  {
    path: PAGE_URL.FORM301_EDIT,
    component: <Form301 mode="edit" />,
    title: "Form301 edit",
  },
  {
    path: PAGE_URL.FORM301_VIEW,
    component: <Form301 mode="view" />,
    title: "Form301 view",
  },
  {
    path: PAGE_URL.FORM301,
    component: <Form301Logs />,
    title: "Form301",
  },
  {
    path: PAGE_URL.CREATE_FORM,
    component: <CustomFormBuilder mode="create" />,
    title: "CustomFormBuilder",
  },
  {
    path: PAGE_URL.CREATE_FORMS,
    component: <CreateForm />,
    title: "CustomFormBuilder",
  },
  {
    path: PAGE_URL.EDIT_FORMS,
    component: <CustomFormBuilder mode="edit" />,
    title: "CustomFormBuilder edit",
  },
  {
    path: PAGE_URL.FORMBUILDER,
    component: <Form />,
    title: "CustomFormBuilder",
  },
  {
    path: PAGE_URL.FORMBUILDER,
    component: <FormRenderer />,
    title: "CustomFormBuilder",
  },

  {
    path: PAGE_URL.FORMS_SELECT,
    component: <Forms />,
    title: "forms",
  },
  {
    path: PAGE_URL.FORMS,
    component: <FormList />,
    title: "forms",
  },
  {
    path: PAGE_URL.FORMS_CREATE,
    component: <SingleForm />,
    title: "forms",
  },

  {
    path: PAGE_URL.CONFIGURE_CHAT,
    component: <ConfigureChat />,
    title: "Configure Assistant",
  },
  {
    path: PAGE_URL.SETTINGS,
    component: <Settings />,
    title: "Settings",
  },
  {
    path: `${PAGE_URL.INTEGRATION}`,
    component: <IntegrationList />,
    title: "Integration Settings",
  },
  {
    path: `${PAGE_URL.WORKSPACE}`,
    component: <Workspace />,
    title: "Workspace Settings",
  },
  {
    path: `${PAGE_URL.USERS}`,
    component: <Users />,
    title: "User Settings",
  },
  {
    path: `${PAGE_URL.CONFIGURE_ACTIONS}`,
    component: <ConfigureAction />,
    title: "Configure Actions Settings",
  },
  {
    path: `${PAGE_URL.BILLINGS_AND_PLANS}`,
    component: <BilingsAndPlans />,
    title: "Billings And Plans",
  },
  {
    path: `${PAGE_URL.SUBSCRIPTION_BILLINGS}`,
    component: <SubscriptionBilling />,
    title: "SUBSCRIPTION",
  },
  {
    path: `${PAGE_URL.WORKFLOW}`,
    component: <Workflow />,
    title: "Workflow",
  },
  {
    path: `${PAGE_URL.RUN_WORKFLOW}`,
    component: <RunWorkflow />,
    title: "Workflow",
  },
  {
    path: `${PAGE_URL.EDIT_WORKFLOW}`,
    component: <EditWorkflow />,
    title: "Edit Workflow",
  },
  {
    path: PAGE_URL.SEARCH_DOC,
    component: <Search />,
    title: "Search",
  },
  {
    path: PAGE_URL.EXTERNAL_ASSISTANT,
    component: <Assistants />,
    title: "Assistants",
  },
  {
    path: `${PAGE_URL.CREATE_EXTERNAL_ASSISTANT}`,
    component: <CreateAssistant />,
    title: "Assistants",
  },
  {
    path: `${PAGE_URL.UPDATE_ASSISTANT}`,
    component: <CreateAssistant />,
    title: "Assistants",
  },
  {
    path: `${PAGE_URL.ASSITANT_CHAT}`,
    component: <AssistantChat />,
    title: "Assistants",
  },
  {
    path: `${PAGE_URL.ASSITANT_CHAT_HISTORY}`,
    component: <AssitantChatHistory />,
    title: "Assistants Chat History",
  },
  {
    path: `${PAGE_URL.PROJECT}`,
    component: <Project />,
    title: "Prtoject",
  },
  {
    path: `${PAGE_URL.REPORT_DASHBOARD}`,
    component: <ReportDashbaord />,
    title: "Dashboard",
  },
];
